
import { Layout } from '@components/common'
import { useT } from '@utils'
import Link from 'next/link'


export default function Custom404({ }) {
    const t = useT()

    return (
        <div className='flex flex-col items-center justify-center'>
            <div className='w-1/2 pt-16 pb-4'>
                <img src='/assets/noMatchImg.svg' className='object-contain'></img>
            </div>
            <span className='text-2xl px-6 font-medium py-4'>{t('The Page you are looking for has been taken...')}</span>
            <Link href='/'>
                <button className='rounded bg-color33 text-white px-10 py-2'>{t('Try again')}</button>
            </Link>
        </div>
    )
}

Custom404.Layout = Layout
